.react-datepicker__tab-loop {
  display: inline-block;
}

.react-datepicker__header {
  background-color: white;
}

.react-datepicker__input-container {
	input {
		border: 0px;
		font-size: 14px;
		text-align: center;

		&:focus {
			outline: none;
			font-size: 14px;
			text-align: center;
		}
	}
}

.react-datepicker__header__dropdown {
  padding: 4px;
}

.react-datepicker__day--in-range {
  color: #167ba0;
  background-color: #fff;

  &:hover {
	color: #fff;
	background-color: #1eabde;
  }
}

.react-datepicker__day--in-range-start {
  color: #fff;
  background-color: #f0df48;
}

.react-datepicker__day--selected {
  color: #fff;
  background-color: #167ba0;
}

.react-datepicker-popper {
  z-index: 1000;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}
