.dynamic-banner-container {
  display: grid;
  grid-template-columns: auto auto minmax(300px, auto-fit);
  grid-template-rows: repeat(6, 1fr);

  .welcome-message {
    grid-column: 1 / span 3;
    grid-row: 1;
    display: flex;
    align-items: center;

    .welcome-text {
      font-size: 1.5em;
      color: #014ca1;
    }
  }

  .background-banner {
    grid-column: 1 / span 3;
    grid-row: 2 / span 5;
    border-radius: 6px;
  }

  .banner-content {
    grid-column: 1 / span 2;
    grid-row: 2 / span 5;
    padding-left: 50px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.1em;
    margin: 35px 0px;

    .banner-header {
      font-size: 2em;

      & > header {
        font-size: 120%;
        font-weight: 600;
        line-height: 100%;
      }

      & > i {
        font-size: 0.9em;
        font-style: italic;
      }
    }

    .banner-text {
      font-size: 1.1em;
    }

    @media (max-width: 1400px) {
      .banner-header {
        font-size: 1.8em;
        font-weight: 600;
      }

      .banner-text {
        font-size: .8em;
      }
    }
  }

  .hero-image {
    grid-column: 3;
    &.inset { grid-row: 2 / span 5; }
    &.outset { grid-row: 1 / span 6; }

    img {
      height: 100%;
      max-height: 350px;
    }
  }
}
