.toolkit-nav-bar {
  padding: 0px 6%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 5px 5px #ebebeb;

  & > span {
    white-space: nowrap;

    & > * {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }
  }

  :global(.menu-item-icon) {
    width: 42px;
    height: 42px;
    margin: 4px;
    border-radius: 3px;
    overflow: hidden;
    &:hover { background-color: #f4f1f1; }
  }

  :global(.ant-input-search) { max-width: 250px; }
}
