.release-notes-list {
	display: flex;
	flex-flow: column nowrap;
	gap: 8px;
	height: 100%;

	:global(.ant-list-pagination){
		margin: 0;
		flex: 0 0 auto;
	}
	:global(.ant-spin-nested-loading){
		flex: 1 1 auto;
		overflow: auto;
	}
	:global(.ant-list-items) {
		:global(.ant-list-item) {
			border-radius: 4px;
			background-color: #f9f9f9;
			margin: 8px 0px;

			:global(.ant-list-item-meta-title){ color: #3b78c2; }
			.components-list { margin-top: 20px; }
		}
	}
}

.tabs {
	&, :global(.ant-tabs-content), :global(.ant-tabs-tabpane){ height: 100%; }
	div { max-height: 100%; }
}

.animated {
	animation-name: anim;
	animation-duration: 1.5s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	transform-origin: center;
}

@keyframes anim {
	  0% { transform: rotate(  0deg); }
	 25% { transform: rotate(-30deg); }
	 35% { transform: rotate(+30deg); }
	 45% { transform: rotate(-30deg); }
	 55% { transform: rotate(+30deg); }
	 65% { transform: rotate(-30deg); }
	 75% { transform: rotate(+30deg); }
	100% { transform: rotate(  0deg); }
}
