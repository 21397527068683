.tooltip-container {
  background-color: #fff;
  padding: 10px 12px 10px;
  box-shadow: 5px 5px 5px rgba(12, 12, 12, 0.1) ;
  border: 1px #f2f2f2 solid;
  border-radius: 4px;
  z-index: 10000;

  .tooltip-date {
    color: rgb(134, 134, 134);
    text-transform: uppercase;
    font-size: 12px;
  }

  .highlight {
    font-size: 1.2em;
    font-weight: 700;
    margin: 5px -5px 5px;
    background-color: rgba(255, 248, 187, 0.479);
  }
}
.tooltip-list {
  text-align: left;
}