.custom-button-default {
  cursor: pointer;
  border: none;
  border-radius: 4px;

  width: 100%;
  color: #fff;
  background-color: #1f66ad;

  display: flex;
  flex-direction: row;
  align-items: center;

  &.primary {
    img {
      opacity: .3;
    }
  }

  &.full {
    justify-content: center;

    .text {
      flex-grow: 0;
    }

  }

  &.card {

    overflow: hidden;

    img {
      max-width: 180px;
      height: 100%;
      min-width: 180px;
      width: 100%;
      padding: 0px;
    }

    .text {
      font-size: 1.7em;
      line-height: 1.5;
    }
  }

  img {
    align-self: flex-start;
    padding: 12px;
    border-radius: 3px;
    max-width: 80px;
    height: 60px;

  }

  .text {
    font-size: 1.5em;
    font-weight: 500;
    flex-grow: 1;
    text-align: center;
    line-height: 2.1;
  }

}

.grade-button:hover {
  color: #fff;
  transition-duration: 200ms;
}