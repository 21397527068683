:global(.ant-input-group.ant-input-group-compact).reports-search-bar {
	display: flex;

	.type-selector {
		flex: 1 1 0;
		:global(.ant-select-selector), :global(.ant-select-arrow) {
			background-color: #1EABDE;
			color: white;
		}
	}
}
