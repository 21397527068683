*, ::before, ::after { box-sizing: border-box }

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

$spanish-color: #ac0d0d;
$pink-color: #ed0677;
$pink-color-light: #f13a92;
$pink-color-dark: #a50954;

#__next, main {
  height: 100%;
  margin: 0;
  padding: 0;
}

.antd-btn-purple:not(.ant-btn-dangerous) {
  border-color: #9932cc;
  &:hover, &:focus { border-color: #b05fd8; }
  &:active { border-color: #7a28a3; }

  &.ant-btn-primary {
    background-color: #9932cc;
    &:hover, &:focus { background-color: #b05fd8; }
    &:active { background-color: #7a28a3; }
  }

  &.ant-btn-default {
    color: #9932cc;
    &:hover, &:focus { color: #b05fd8; }
    &:active { color: #7a28a3; }
  }
}

.antd-btn-pink:not(.ant-btn-dangerous) {
  border-color: $pink-color;
  &:hover, &:focus { border-color: $pink-color-light; }
  &:active { border-color: $pink-color-dark; }

  &.ant-btn-primary, &.ant-btn-default  {
    background-color:  $pink-color;
    color: white;
    &:hover, &:focus { background-color: $pink-color-light; }
    &:active { background-color: $pink-color-dark; }
  }
}

.subtitle {
  font-size: 0.95em;
  color: #808080;
  font-style: italic;
}

.pie-chart-holder {
  height: 280px;
  position: relative;

  .middle-box {
    font-size: 1.5em;
    position: absolute;
    line-height: 220px;
    top: 0;
    height: 250px;
    text-align: center;
    width: 100%;
    pointer-events: none;
  }
}

.button-group {
  .ant-btn {
    margin-right: 12px;
  }
}

.resized-pie-chart {
  height: 400px;
  margin: 0px auto;
}

.histogram-holder {
  height: 400px;
  position: relative;
}

.history-chart-holder {
  height: 500px;
  position: relative;
}

.chart-holder {
  height: 300px;
  position: relative;
}

.cku-progress-holder {
  position: relative;
  display: inline-block;
  margin-top: 10px;

  .middle-cku-progress-bar {
    position: absolute;
    border: 0px;
    border-radius: 0px;
    right: 20px;
    left: 111px;
    bottom: 32px;
    height: 36px;

    .cku-progress-value {
      height: 100%;
    }

    .middle-text {
      position: absolute;
      line-height: 37px;
      font-size: 20px;
      text-align: center;
      left: 0;
      right: 0;
      font-weight: bold;
    }
  }
}

.leadership-tab-holder {
  background-color: #ffffff;
}

.reformatted-card {
  .usage-card-header {
    height: 60px;
    color: #6b6b6b;
  }
}

.ant-card-actions {
  border-top: none;
}

.ant-card-meta-description {
  color: #2e2e2e;
}

.ant-card-actions>li {
  float: left;
  margin: 12px;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
}

.usage-card-holder {
  height: 100%;

  .usage-card-header {
    height: 40px;
    color: #444444;
  }
}

.widget-date-span {
  margin-top: 10px;
  padding: 6px;
  text-align: center;
}

.custom-marker-user-average-minutes {
  font-weight: 500;
  font-size: 12px;
}

.user-average-minutes-holder {
  width: 100%;
  height: 150px;
}

.ant-table-column-title {
  .subtitle {
    font-size: 0.8em;
    color: #808080;
    font-style: italic;
  }
}

.helper-text {
  color: rgba(109, 108, 108, 0.65);
  font-size: small;
}

.ant-statistic {
  &.normal-size {
    .ant-statistic-content {
      font-size: 1em;
    }
  }
}

.ant-input-search {
  &.gradebook-search {
    width: 30em;
  }
}

.section-header-title {
  font-weight: 500;
  font-size: large;
  color: #3378c7;
}

.achievement-container {
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2a8;
  border-radius: 8px;
  padding: 6px;

  .achievement-image-container {
    max-width: 70px;
    margin-right: 12px;
    padding: 6px;
    border-radius: 8px;
  }

  .achievement-image {
    width: 100%;
  }

  .achievement-data {
    padding-top: 6px;
  }

  .ant-statistic-title {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1em;
  }

  .ant-statistic-content {
    font-weight: 500;
    font-size: 1.5em;
    color: #4b484c;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.compact {
  .achievement-image-container {
    max-width: 55px;
  }

  .ant-statistic-title {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.8em;
    color: #2e2e2e;
  }

  .ant-statistic-content {
    font-weight: 500;
    font-size: 1.2em;
  }
}

.performance-table-category {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.table-chart-holder {
  position: relative;
  height: 40px;
}

.attempts-list { // in assignments reports
  padding: 0px;

  li {
    margin-left: 2ch;
    &::marker { color: #DCDEDC; }
  }
}


// Enrollment Picker
.enrollment-dropdown {
  .ant-select-selection {
    border: 0px;
  }

  .ant-select-selection:focus {
    box-shadow: none;
  }
}

// *****************
//  Home Page Layout - Start
// *****************

.app-header {
  background: #fff !important;
  padding-left: 20px;
  border-bottom: 2px solid #ffc400;
}

.header-logo-container {
  margin-left: 10px;
  display: flex;

  a {
    line-height: normal;
  }
}

.header-logo {
  max-height: 28px;
}

.header-title {

  margin-left: 10px;

  span {
    color: #206986;
    font-size: 1.5em;
    font-weight: 500;
  }
}

.menu-icon {
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: #1eabde;
}

.title-page {
  color: #196986;
  text-transform: capitalize;

  .anticon svg {
    font-size: 30px;
  }
}

.tutorial-video-container {
  display: inline-block;
}

.spacer-div {
  margin-top: 12px;
  margin-bottom: 1px;
}

.row-container {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  height: 100%;

  .row-container-header {
    flex-grow: 1;
    display: flex;
    margin: 0px 0px 10px;
    justify-content: space-between;

    .title {
      font-size: 1.3em;
      font-weight: 600;
    }
  }

  .row-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
  }
}

.resources-row {
  margin-top: 18px;
}

// Library Button
.library-button {
  position: relative;
  height: 100%;
  background-image: url('/assets/background-library.jpg');
  background-size: cover;
  border-radius: 6px;
  text-align: center;

  .ant-image {
    position: relative;
    margin: 0px auto;
  }
}

//Book Library

.book-library-pageheader {
  background-color: #1890ff;

  h2 {
    font-size: 1.4em;
    font-weight: 600;
  }
}

.library-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.library-thumbnail {
  position: relative;
  max-width: 160px;
  min-width: 100px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  figure {
    margin-bottom: 0px;
  }

  img {
    width: 100%;
  }
}

.page-desc {
  max-width: 960px;
  padding: 6px 12px;
}

// *****************
//  Footer - Start
// *****************

.ant-layout-footer {
  background-color: #fff;
}

.landing-footer-container {
  display: flex;
  padding: 8px 24px;
  justify-content: space-between;
}

.copyright {
  text-align: center;
  font-size: 0.8em;
  color: #808080;
}

.build-number {
  font-size: 0.8em;
  color: #999;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ant-layout-footer:hover .build-number {
  opacity: 1;
}

.landing-footer {
  width: 30%;
}

.landing-footer-left {
  display: inline-block;
}

.landing-footer-right {
  float: right;
  text-align: right;
}

.footer-link-list {
  list-style: none;
}

// *****************
//  Footer - End
// *****************

.content-description {
  text-align: right;
}

.content-extra {
  margin-top: 15px;

  float: right;

  .ant-btn {
    margin: 0px 4px;
  }
}

.demo-link-list {
  list-style: none;

  li {
    display: inline;
    padding: 5px;
  }
}

.date-picker-container {
  float: right;

  .ant-input-disabled {
    border: #000000;
    background-color: transparent;
    color: #00000099;
  }
}

.menu-skeleton-contatiner {
  padding: 24px;
}

.floating-button-container {
  position: fixed;
  bottom: 120px;
  right: 20px;
  z-index: 10000;

  .ant-btn-lg {
    background-color: #ec0c9d;
    border-color: #ec0c9d;
    font-size: 24px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }

  .ant-btn-lg:hover {
    background-color: #ff4a84;
    border-color: #ff4a84;
  }
}

.video-player-modal {
  width: 80% !important;
  height: 80% !important;
}

.popover-content {
  max-width: 180px;
}

.thumbnail-popover-container {

  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;

  .data-row {
    font-size: 12px;
    max-width: 300px;
    padding-bottom: 10px;


    .label {
      text-transform: uppercase;
      font-weight: 600;
    }

    .description {
      display: inline;

    }
  }

  .inline-list-item {
    display: inline;
    margin-right: 10px;
  }
}

.pop-up-display {
  width: 100%;
  margin: 5px 0px;
  padding: 20px 0px 10px;
}

.page-parameter-header {
  margin: 0px -10px;
  background-color: #f9fbfc;
  padding: 7px 12px;
  box-shadow: inset 0 7px 9px -7px rgba(158, 154, 154, 0.2);
}

.header-content {
  display: flex;
  flex-direction: row;

  .page-header-main {
    flex-grow: 3;
  }

  .page-header-extra {
    flex-grow: 1;
  }
}

.pageheader-crumb {
  color: #747474;
}

.interaction-descriptions {
  font-size: 13px;
  margin-bottom: 4px;

  .interaction-date {
    color: #2e2e2e;
  }
}

.leaderboard-breadcrumbs {
  display: inline-block;
  padding: 6px 8px;
  margin-left: 6px;
  background-color: #e6e6e6;
  border-radius: 4px;

  .ant-breadcrumb {
    color: #1eabde;
    font-weight: 400;
  }

  .ant-breadcrumb span:last-of-type {
    color: rgba(78, 72, 72, 0.65);
  }
}

.schooltype-tag {
  font-size: 0.6em !important;
  line-height: 12px !important;
  border: white !important;
}

.button-tag {
  margin-left: 6px !important;
}

.table-tag {
  margin-bottom: 6px !important;
}

.advanced-filter-container {
  max-width: 700px;
  margin: -20px 0px 10px !important;

  .ant-form-item {
    margin-bottom: 10px !important;
  }
}

.filter-search {
  width: 317px !important;
  max-width: 365px;
  margin-right: 10px;
}

.selection-header {
  margin-bottom: 20px !important;

  .ant-select-selection {
    font-size: 1.3em;
    border: none;
    border-radius: 0px;
    border-bottom: #62a8f8 solid 1px;
  }

  .ant-select-selection:focus {
    box-shadow: none;
  }
}

.column-list-menu {
  border-right: 0px !important;
}

.ant-pagination {
  .ant-pagination-item-active {
    background-color: #1eabde;

    a {
      color: #fff;
    }
  }
}

.param-component {
  display: inline-block;
  padding: 6px 20px;
  border-radius: 8px;
  margin-right: 6px;
}

.app-picker-section {
  text-align: right;
}

// Assignment Search
.ant-col .search-column {
  padding: 0px 24px;

  h3 {
    display: inline-block;
  }
}

.title-container {
  margin-top: 30px;
  text-align: center;
}

.search-container {
  margin-bottom: 20px;
}

.label-input-container {
  margin: 10px 0px;
  // width: 100%;

  .label-container {
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  .ant-select {
    width: 100%;
  }
}

.link-list {
  padding-left: 10px;

  .horizontal-list-items {
    padding: 3px 10px;
  }

  .vertical-list-items {
    display: block;
    padding: 3px 10px;
  }
}

.activity-card-title {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.activity-card-description {
  color: rgb(158, 158, 158);
  min-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.step-title {
  margin-bottom: 1em;
  color: #1185b8;
  text-transform: uppercase;
}

.upload-container {
  display: inline-block;
}

.inline-header {
  h4 {
    display: inline-block;
    color: #6a6a6a;
  }

  h3 {
    color: #2c2c2c;
    display: inline-block;
  }

  span {
    color: #9c9c9c;
  }
}

// Playlist styles
.ant-card {
  .playlist-summary {
    background-color: #fffdfd;

    .description {
      font-size: 16px;
      display: flex;
      flex-direction: row;
      padding: 6px;

      .label {
        flex-grow: 1;
      }

      .value {
        font-weight: 600;
      }
    }

    .ant-btn.submit-btn {
      color: white;
      background-color: #6db223;
      border: none;
      margin-top: 5px;

      &:hover { background-color: #7ccf24; }
      &[disabled] { background-color: #dcdcdc; }
    }
  }
}

.axis-link>a {
  fill: #1eabde;

  &:visited {
    fill: #1890ff;
  }

  &:hover {
    fill: #6bcef2;
  }
}

.playlist-actions {
  display: block;
  padding: 6px 40px 12px;
}

.playlist-actions-fixed {
  background-color: #fff;
}

.playlist-container {
  .ant-tree .ant-tree-treenode {
    display: flexbox;
    padding: 8px 0 4px 0;
  }

  .playlist-tree-title {
    padding: 6px 12px;
    margin-top: -6px;
    background-color: #f3f3f3;
    border-left: #1eabde solid 3px;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    display: inline-flex;
    flex-direction: row;

    span {
      flex-grow: 1;
    }
  }
}

.inline-datepicker {
  display: inline-block;
  margin-left: 30px;
  font-size: 13px;
  font-weight: 400;

  .ant-picker {
    border: 1px solid #5ebcdf;
  }
}

.tab-content {
  padding: 0px 20px;
}

// Sider Filter

.filter-container {
  border-radius: 8px;
  padding: 10px 30px 10px 30px;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .filter-form {
    .ant-form-item-label {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.search-results-container {
  display: flex;
  flex-direction: row;
  gap: 18px;

  .search-filter-aside {
    background-color: #f6f6f6;
    border-radius: 4px;
    min-width: 300px;
    max-width: 450px;
  }

  .search-results {
    flex: 1 1 0;
    min-width: 0;
    border: 1px solid #f6f6f6;
    padding: 20px;
  }
}



// Content Page 

.page-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  .page-main-content {
    flex-grow: 1
  }

  aside {
    min-width: 300px;
    flex: initial
  }
}
.grade-resources-header {
  background-color: #fff;
  margin: 10px 0px;
  padding: 20px 30px 0px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  gap: 10px 30px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  .title-content {
    flex: 1 fit-content 0%;
    margin-bottom: 10px;

    .title {
      flex-grow: 2;
      font-size: 2em;
      font-weight: 600;
      gap: 10px;

      .secondary-title {
        padding-left: 10px;
        font-style: italic;
        font-weight: 500;
        color: $spanish-color
      }
    }
  }


  .description {
    flex: 1 2;
    border-left: 1px solid #f1f1f1;
  }

  .extra-content {
    align-self: center;
    margin-left: auto;
  }

  .content-page-menu {
    flex: 0 0 100%;

    ol {
      list-style: none;
      padding-left: 0px;
      margin-bottom: 0;

      li {
        display: inline-flex;

        a {
          padding: 10px;
          color: #1185b8;
          border-bottom: 3px solid #fff;
          font-weight: 500;

          &:hover {
            background-color: #f5f5f5;
            border-bottom: 3px solid #b8dcfc;
          }

          &:active {
            background-color: #f5f5f5;
          }

          &.current, &:active {
            border-bottom: 3px solid #1185b8;
          }
        }
      }
    }
  }
}

// Header Test

.lesson-header-text {
  padding: 5px 12px;

  p {
    margin-bottom: 3px;
  }

  figure {
    margin-bottom: 0px;
  }

  tbody {
    text-align: left;
    vertical-align: top;

    td {
      padding-right: 30px;
    }
  }
}

// Content Preview Contatiner

.preview-container {
  display: flex;
  flex-direction: row;
  gap: 2em;

  .preview-frame {
    flex-grow: 1;
    border-radius: 6px;
    border: #f1f0f0 solid 1px;
    overflow: hidden;
    width: 568px;

    #frame {
      .nav-header {
        display: none;
      }
    }
  }

  .preview-resources {
    flex-grow: 1;
  }
}

.preview-nav-container {
  text-align: center;
  background-color: sienna;
}

.preview-nav-display {
  text-align: center;
  padding: 5px 15px;
}

.preview-select {
  flex-grow: 1;

  .ant-select-selection {
    color: #1eabde;
    border: none !important;
  }
}

.ant-col .preview-column {
  max-width: 700px;
  margin: 0px auto;
}

.preview-module-container {
  max-width: 1600px;
  margin: 10px auto;
}

.resources-information-container {
  display: flex;
  flex-direction: column;
  padding: 0px 25px;

  >div {
    margin-bottom: 20px;
  }

  .resource-info {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .related-resources {
    background-color: #9cdeff;
    border-radius: 6px;

    .title {
      font-size: 1.5em;
    }
  }
}

.labeled-content-container {
  display: flex;
  flex-direction: row;
  border-bottom: #0000000a solid 1px;
  margin-bottom: 10px;
  align-items: start;

  .label {
    font-size: 1.1em;
    font-weight: 600;
    text-transform: capitalize;
    padding-right: 10px;
  }

  .content {
    padding-bottom: 5px;

    span:not(:first-child) {
      border-left: #c9c9c9 solid 1px;
    }
  }

  .spanish-label-content {
    color: $spanish-color
  }
}

.placeholder-thumbnail {
  text-align: center;
  overflow: hidden;
  height: 90px;
  border-radius: 4px;

  .placeholder-text {
    position: absolute;
    top: 0;
    margin: 3px 10%;
    font-size: 1em;
    font-weight: 600;
    color: #fff;
  }
}

.resources-list {
  figure {
    margin: 0;
  }

  ol {
    list-style: none;
    padding-left: 0px;

    li {
      font-size: 1.1;
      line-height: 2;
    }
  }

  table {
    width: 100%;
  }

  tbody {
    text-align: left;
    vertical-align: top;

    td {
      padding-left: 20px;

      strong {
        font-size: 1.2em;
      }
    }
  }
}

// HighlighedContent

.highlighted-container {
  padding: 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  .header {
    border-bottom: rgba(184, 184, 184, 0.129) solid 0.5px;
  }

  .title {
    font-size: 1.3em;
    font-weight: 500;
  }

  .content {
    margin-top: 5px;
  }

  .right-side {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
  }
}

@media print {
  @page {
    size: letter landscape;
  }

  .app-header,
  .achievement-image-container,
  .ant-page-header-footer,
  .no-print {
    display: none !important;
  }

  .achievement-data {
    text-align: center;
    width: 100% !important;
  }
}
