.search-container { padding: 8px 16px; }

.drawer {
  .nav-ancestry {
    background-color: #f2f4f7;
    border-right: thin solid rgb(232, 232, 232);
    border-bottom: thin solid #ffc400;
    border-top: thin solid #ffc400;

    .semester-selector {
      width: 100%;
      padding: 8px 16px;

      & > .ant-select { width: 100%; }
    }

    .ant-menu {
      .ant-menu-item-selected {
        font-weight: 500;
        background-color: #f2f4f7;
        color: #1eabde !important;
      }
    }
  }

  .breadcrumb {
    background-color: transparent;
    border-right: 0;

    .last-breadcrumb-link {
      color: #1eabde !important;
      font-weight: 500;
    }
  }

  :global(.ant-select) {
    width: 100%;
  }

  :global(.data-menu-link) {
    font-weight: 600;
  }
}
