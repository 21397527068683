.chart-subtitle {
  font-size: 12px;
  fill: #999999;
}

.bar-chart-label {
  font-size: small;
  font-weight: 400;
}

.on-chart {
  fill: #ffffff;
}
.off-chart {
  fill: #007fd4;
}
