ul.reports-search-result-list {
	list-style: inside none;
	padding: 0px;
	margin: 20px 0px;

	li {
		border-top: #ebebeb solid 1px;
		padding: 20px 0px;
		&:last-child { border-bottom: #ebebeb solid 1px; }
	}

	article {
		header {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;

			h1 { margin: 0 }

			a {
				span {
					color: #1EABDE;

					&.secondary-title {
						font-weight: 500;
						color: #AC0D0D;
					}
				}

				&:hover {
					span {
						color: #1771bf;

						&.secondary-title { color: rgb(204, 92, 0); }
					}
				}
			}
		}

		section {
			display: flex;
			gap: 4px;

			& > * { flex: 1 1 0; }

			dl {
				margin: 0;

				dt, dd {
					display: inline-block;
					margin: 0;
				}
			}
		}

		aside {
			display: inline-flex;
			flex-direction: column;
			align-items: stretch;
			gap: 4px;
		}

		.meta {
			span:first-child { font-weight: bold; }

			.district-group {
				margin: 16px 0px;
				&:first-child { margin-top: 0; }
			}
			.school-group {
				margin: 8px 0px;
				&:only-child { margin-top: 0; }
			}
			.district-group, .school-group {
				&:last-child { margin-bottom: 0; }
			}
		}
	}
}
